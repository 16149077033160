<template>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <PanelBox title="Logo">
        <span slot="toolbar">
          <button
            v-show="project.logo"
            type="button"
            title="Logo verwijderen"
            class="toolbar-button"
            @click="removeLogo"
          >
            <i class="fas fa-trash" />
            Verwijderen
          </button>
        </span>
        <div class="tw-w-full">
          <img
            v-if="project.logo"
            alt="Projectlogo"
            :src="project.logo"
            class="tw-mx-auto tw-max-h-40 tw-rounded-lg"
          />
          <FileDropzone
            v-else
            :url="logoUploadUrl"
            :max-files="1"
            :labels="{
              loading: 'Logo uploaden',
              static: 'Upload logo'
            }"
            method="patch"
            param-name="logo"
            @file-uploaded="loadProject(projectId)"
          />
        </div>
      </PanelBox>
      <PanelBox title="Instellingen">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectInfoEdit', params: { formName: 'settings' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Niche</dt>
          <dd>{{ project.niche.name.text_nl || 'Niet ingegeven' }}</dd>
          <dt>Promotor</dt>
          <dd>{{ (project.promoter && project.promoter.name) || '-' }}</dd>
          <dt>Architect</dt>
          <dd>{{ (project.architect && project.architect.name) || '-' }}</dd>
          <dt>Aantal units</dt>
          <dd>{{ project.number_of_entities }}</dd>
          <dt>Aantal verkocht in %</dt>
          <dd>
            <span class="progress progress-small" style="margin-bottom: 0; width: 90%; display: inline-block;">
              <div class="progress-bar" :style="{ width: project.percentage_sold + '%' }" />
            </span>
            <small style="float: right; padding-right: 10px; padding-top: 2px">
              {{ project.percentage_sold }}%
            </small>
          </dd>
          <dt>Status</dt>
          <dd>{{ project.state_display }}</dd>
          <dt>Actieve fase</dt>
          <dd>{{ project.fase_display }}</dd>
          <dt>Opleverdatum</dt>
          <dd>{{ (project.delivery_date && project.delivery_date.text_nl) || '-' }}</dd>
          <dt>Min./max. prijs</dt>
          <dd>{{ (project.price_min_max_description && project.price_min_max_description.text_nl) || '-' }}</dd>
          <dt>Slaapkamers</dt>
          <dd>{{ (project.bedrooms_description && project.bedrooms_description.text_nl) || '-' }}</dd>
          <dt>Min./max. bruto vloeroppervlakte</dt>
          <dd>{{ (project.surface_min_max_description && project.surface_min_max_description.text_nl) || '-' }}</dd>
          <dt>Parking/staanplaats</dt>
          <dd>{{ (project.parking_car_pitch_description && project.parking_car_pitch_description.text_nl) || '-' }}</dd>
          <dt>Tuin/terras</dt>
          <dd>{{ (project.terrace_garden_description && project.terrace_garden_description.text_nl) || '-' }}</dd>
        </dl>
      </PanelBox>
      <PanelBox title="Kenmerken">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectInfoEdit', params: { formName: 'features' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <dt>Nieuwbouw</dt>
          <dd>{{ project.is_newly_built | yesno }}</dd>
        </dl>
      </PanelBox>
    </div>

    <div class="col-md-12 col-lg-6">
      <PanelBox title="Wettelijke vermeldingen">
        <span slot="toolbar">
          <button
            title="Weergeven"
            type="button"
            class="toolbar-button tw-mr-2"
            @click="showLegalNotices"
          >
            <i class="fas fa-info-circle" /> weergeven
          </button>
          <router-link
            :to="{ name: 'ProjectLegalEdit' }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <template v-if="legal">
          <h3>Stedenbouwkundige informatie</h3>
          <dl v-if="project.region === 1" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Status</dt>
            <dd>{{ legal.status_display }}</dd>
            <dt>Datum aanvraag</dt>
            <dd>{{ legal.request_date | date-day | default_if_none('-') }}</dd>
          </dl>

          <template v-else>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Stedenbouwkundige vergunning</dt>
              <dd>{{ legal.building_permit | yes_no_request }}</dd>
              <dt>Type bestemming</dt>
              <dd>{{ legal.destination_type_display || '-' }}</dd>
              <dt>Type secundaire bestemming</dt>
              <dd>{{ legal.destination_type_secundairy_display || '-' }}</dd>
              <dt>Bestemming - extra informatie</dt>
              <dd>{{ (legal.destination_info && legal.destination_info.text_nl) || '-' }}</dd>
              <dt>Bestuursdwang</dt>
              <dd>{{ legal.administrative_order | yes_no_request }}</dd>
              <dt>Minnelijke schikking</dt>
              <dd>{{ legal.amicable_settlement | yes_no_request }}</dd>
              <dt>Last onder dwangsom</dt>
              <dd>{{ legal.penalty_payment | yes_no_request }}</dd>
              <dt>Rechterlijke herstelmaatregel</dt>
              <dd>{{ legal.remedial_action | yes_no_request }}</dd>
              <dt>Dagvaardingen</dt>
              <dd>{{ legal.subpoenas | yes_no_request }}</dd>
              <dt>Handhaving - extra informatie</dt>
              <dd>{{ (legal.subpoenas_info && legal.subpoenas_info.text_nl) || '-' }}</dd>
              <dt>Voorkooprecht</dt>
              <dd>{{ legal.preemption | yes_no_request }}</dd>
              <dt>Voorkooprecht - extra informatie</dt>
              <dd>{{ (legal.preemption_description && legal.preemption_description.text_nl) || '-' }}</dd>
              <dt>Verkavelingsvergunning</dt>
              <dd>{{ legal.allotment_permit | yes_no_request }}</dd>
              <dt>Afkortingen</dt>
              <dd v-html="legal.short_code" />
            </dl>

            <h3>Watertoets</h3>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <template v-if="showWaterTestOldFields">
                <dt>Effectief overstromingsgevoelig</dt>
                <dd>{{ legal.flooding_effective | yes_no_not_entered }}</dd>
                <dt>Mogelijk overstromingsgevoelig</dt>
                <dd>{{ legal.flooding_possible | yes_no_not_entered }}</dd>
              </template>
              <dt>P-scores</dt>
              <dd>
                <span v-for="(score, index) in legal.p_scores" :key="`p_score_${index}`" class="tw-block">
                  Perceelnummer {{ score.plot_number }}: {{ score.value }}
                </span>
              </dd>
              <dt>G-scores</dt>
              <dd>
                <span v-for="(score, index) in legal.g_scores" :key="`g_score_${index}`" class="tw-block">
                  Gebouw-ID {{ score.plot_number }}: {{ score.value }}
                </span>
              </dd>
              <dt>Afgebakend overstromingsgebied</dt>
              <dd>{{ legal.flooding_plain | yes_no_not_entered }}</dd>
              <dt>Afgebakende oeverzone</dt>
              <dd>{{ legal.flooding_bank | yes_no_not_entered }}</dd>
              <dt>Risicozone voor overstromingen</dt>
              <dd>{{ legal.flooding_risk | yes_no_not_entered }}</dd>
              <dt>Overstromingsgevoeligheid - extra informatie</dt>
              <dd>{{ (legal.flooding_info && legal.flooding_info.text_nl) || '-' }}</dd>
            </dl>
            <h3>Onroerend erfgoed</h3>
            <h4 class="tw-italic">Beschermd onroerend erfgoed</h4>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Beschermd monument</dt>
              <dd>{{ legal.heritage_protected_monument | yes_no_not_entered }}</dd>
              <dt>Beschermd landschap</dt>
              <dd>{{ legal.heritage_protected_area | yes_no_not_entered }}</dd>
              <dt>Beschermd cultuurhistorisch landschap</dt>
              <dd>{{ legal.heritage_protected_cultural_area | yes_no_not_entered }}</dd>
              <dt>Beschermd stadsgezicht</dt>
              <dd>{{ legal.heritage_protected_city | yes_no_not_entered }}</dd>
              <dt>Beschermd dorpsgezicht</dt>
              <dd>{{ legal.heritage_protected_village | yes_no_not_entered }}</dd>
              <dt>Beschermde archeologische site</dt>
              <dd>{{ legal.heritage_protected_archaeological_site | yes_no_not_entered }}</dd>
              <dt>Beschermde archeologische zone</dt>
              <dd>{{ legal.heritage_protected_archaeological_zone | yes_no_not_entered }}</dd>
              <dt>Beschermde archeologisch monument</dt>
              <dd>{{ legal.heritage_protected_archaeological_monument | yes_no_not_entered }}</dd>
              <dt>Onroerend erfgoed - extra informatie</dt>
              <dd>{{ (legal.heritage_info && legal.heritage_info.text_nl) || '-' }}</dd>
            </dl>
            <h4 class="tw-italic">Geïnventariseerd onroerend erfgoed</h4>
            <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <dt>Landschapsatlas</dt>
              <dd>{{ legal.heritage_landscape_atlas | yes_no_not_entered }}</dd>
              <dt>Inventaris van archeologische zones</dt>
              <dd>{{ legal.heritage_inventory_archaeological_zones | yes_no_not_entered }}</dd>
              <dt>Inventaris van bouwkundig erfgoed</dt>
              <dd>{{ legal.heritage_inventory_architectural | yes_no_not_entered }}</dd>
              <dt>Inventaris van houtige beplantingen met erfgoedwaarde</dt>
              <dd>{{ legal.heritage_inventory_planting | yes_no_not_entered }}</dd>
              <dt>Inventaris van historische tuinen en parken</dt>
              <dd>{{ legal.heritage_inventory_historical_gardens_parks | yes_no_not_entered }}</dd>
              <dt>Geïnventariseerd onroerend erfgoed - extra informatie</dt>
              <dd>{{ (legal.heritage_inventory_info && legal.heritage_inventory_info.text_nl) || '-' }}</dd>
            </dl>
          </template>
        </template>
      </PanelBox>

      <PanelBox title="Energie">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectInfoEdit', params: { formName: 'energy' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <dl v-if="energy" class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
          <template v-if="energy.epb_in_draft">
            <dt>EPB in opmaak</dt>
            <dd>{{ energy.epb_in_draft | yesno }}</dd>
          </template>
          <template v-else>
            <dt>EPB-kengetal</dt><dd>{{ energy.epb || '-' }}</dd>
            <dt>EPB-label</dt><dd>{{ energy.epb_label || '-' }}</dd>
            <dt>UC</dt><dd>{{ energy.uc || '-' }}</dd>
            <dt>E-peil</dt><dd>{{ energy.e_value || '-' }}</dd>
            <dt>EPB-opstellingsdatum</dt><dd>{{ energy.epb_date || '-' }}</dd>
          </template>

        </dl>
      </PanelBox>
      <PanelBox title="Eigen website">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectInfoEdit', params: { formName: 'website' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <div v-if="marketing" class="row">
          <dl class="dl-horizontal">
            <dt style="text-align: left; padding-left: 15px;">Tabel vorm</dt>
            <dd>{{ marketing.table_form_display || '-' }}</dd>
          </dl>

          <div
            v-for="{ title, key, texts } in websiteTabs"
            :key="key"
            class="tw-pb-4 tabs-container col-sm-12"
          >
            <h3>{{ title }}</h3>

            <ul class="nav nav-tabs">
              <li
                v-for="lang in ['nl', 'fr', 'en']"
                :key="`tab-${key}-${lang}`"
                :class="{ 'active': lang === 'nl' }"
              >
                <a
                  :href="`#tab-${key}-text_${lang}`"
                  aria-expanded="true"
                  data-toggle="tab"
                  class="tw-uppercase"
                >
                  {{ lang }}
                </a>
              </li>
            </ul>

            <div class="tab-content tab-pane panel-body">
              <!-- name can be text_nl, text_fr, or text_en -->
              <div
                v-for="(value, name) in texts"
                :key="`content-${key}-${name}`"
                :id="`tab-${key}-${name}`"
                :class="['tab-pane', { 'active': name === 'text_nl' }]"
              >
                {{ value || '-' }}
              </div>
            </div>
          </div>
        </div>
      </PanelBox>
    </div>
    <EntityLegalNotices ref="legalNoticesModal" :project=project />
  </div>
</template>

<script>
import PanelBox from '@/components/iam/PanelBox'
import EntityLegalNotices from '@/components/properties/EntityLegalNotices'
import FileDropzone from '@/components/iam/FileDropzone'

import { mapActions, mapGetters } from 'vuex'
import { errorModal, questionModal } from '@/modalMessages'
import {
  getProjectEnergy,
  getProjectLegal,
  updateProjectLogo,
  getProjectMarketing
} from '@/services/projects'

export default {
  name: 'ProjectInfo',
  components: {
    PanelBox,
    EntityLegalNotices,
    FileDropzone
  },
  constants: {
    TABLE_FORM_OPTIONS: {
      1: 'Teaser',
      2: 'Volledig'
    }
  },
  data () {
    return {
      energy: null,
      legal: null,
      marketing: null
    }
  },
  computed: {
    ...mapGetters('properties', ['getProjectById']),

    projectId () {
      return this.$route.params.id
    },
    project () {
      return this.getProjectById(this.projectId)
    },
    logoUploadUrl () {
      return `/api/v3/project/${this.projectId}/logo`
    },
    websiteTabs () {
      const {
        call_to_action = {},
        strength_1 = {},
        strength_2 = {},
        strength_3 = {}
      } = this.marketing

      return [
        { title: 'Call to action', key: 'call_to_action', texts: call_to_action },
        { title: 'Troef 1', key: 'strength_1', texts: strength_1 },
        { title: 'Troef 2', key: 'strength_2', texts: strength_2 },
        { title: 'Troef 3', key: 'strength_3', texts: strength_3 }
      ]
    },
    showWaterTestOldFields () {
      if (!this.project?.created_on) return false
      const createdDate = new Date(this.project.created_on)
      const firstOfJanuary2023 = new Date('2023-01-01')
      return firstOfJanuary2023.getTime() - createdDate.getTime() > 1
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', ['loadProject']),

    async init () {
      return await Promise.all([
        this.loadProjectMarketing(this.projectId),
        this.loadProjectLegal(this.projectId),
        this.loadProjectEnergy(this.projectId)
      ])
    },
    showLegalNotices () {
      this.$refs.legalNoticesModal.show()
    },
    async loadProjectEnergy (projectId) {
      const response = await getProjectEnergy(projectId)
      this.energy = response.data
      return response
    },
    async loadProjectLegal (projectId) {
      const response = await getProjectLegal(projectId)
      this.legal = response.data
      return response
    },
    async loadProjectMarketing (projectId) {
      const response = await getProjectMarketing(projectId)
      this.marketing = response.data
      return response
    },
    async removeLogo () {
      try {
        const result = await questionModal('Weet u zeker dat u het logo wilt verwijderen?')
        if (!result.value) return

        const response = await updateProjectLogo(this.projectId, null)
        await this.loadProject(this.projectId)
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij verwijderen van projectlogo, probeer het opnieuw.')
      }
    }
  }
}
</script>
