<template>
  <Modal ref="modal" title="Wettelijke vermeldingen">
    <div class="tabs-container">
      <ul class="nav nav-tabs">
        <li class="active"><a data-toggle="tab" href="#tab-short">Kort</a></li>
        <li><a data-toggle="tab" href="#tab-long">Lang</a></li>
      </ul>
      <div class="tab-content">
        <div
          v-for="(languages, noticeType, index) in noticeData"
          :key="noticeType"
          :id="`tab-${noticeType}`"
          :class="['tab-pane panel-body', { 'active': index === 0 }]"
        >
          <ul class="nav nav-tabs">
            <li
              v-for="(notices, lang, index) in languages"
              :key="lang"
              :class="{ 'active': index === 0 }"
            >
              <a data-toggle="tab" :href="`#tab-${noticeType}-${lang}`" class="tw-uppercase">
                {{ lang }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <!-- It is very important to separate the ids of the different kind of tabs -->
            <!-- Hence, we use a combination of noticeType and lang -->
            <table
              v-for="(notices, lang, index) in languages"
              :key="lang"
              :id="`tab-${noticeType}-${lang}`"
              :class="['tab-pane panel-body', { 'active': index === 0 }]"
            >
              <tbody v-for="(notice, index) in notices" :key="index">
                <template v-if="notice.items">
                  <th v-text="notice.title" class="tw-px-2 tw-text-sm tw-underline" />
                  <tr>
                    <th v-text="notice.info" class="tw-px-4 tw-font-normal tw-italic" />
                  </tr>
                  <tr v-for="(item, index) in notice.items" :key="index">
                    <th v-text="item.label" class="tw-px-4" />
                    <td>
                      <p v-html="item.value || '-'" /> <!-- There can be html in the value field -->
                      <p v-text="item.info" class="tw-italic" />
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td v-html="notice || '-'" /> <!-- There can be html in the value field -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { getPropertyLegalNotices } from '@/services/properties'
import { getProjectLegalNotices } from '@/services/projects'
import { errorModal } from '@/modalMessages'

export default {
  name: 'EntityLegalNotices',
  components: { Modal },
  props: {
    property: {
      type: Object,
      default: null
    },
    project: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      noticeData: {}
    }
  },
  methods: {
    async init () {
      try {
        const response = this.property ? await getPropertyLegalNotices(this.property.id) : await getProjectLegalNotices(this.project.id)
        this.noticeData = response.data
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van wettelijke vermeldingen, probeer het opnieuw.')
      }
    },
    async show () {
      await this.init()
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>

<style scoped>
th, td {
  @apply tw-py-2;
  @apply tw-align-top;
}
</style>
